function queryParser(queryString) {
  const queryTargetings = {};
  const queryArray = queryString.replace(/^\?/, "").split("&");

  queryArray.forEach((query) => {
    query = query.split("=");
    if (!query[1]) return;

    const key = query[0];
    const value = query[1];

    if (queryTargetings[key]) {
      queryTargetings[key].push(value);
    } else {
      queryTargetings[key] = [value];
    }
  });

  return queryTargetings;
}

export {
  queryParser
};
