/* eslint-disable camelcase */
/* eslint-disable no-alert */
import { getAdInfoDialog, getAdInfoReportDialog, getReportSuccessDialog, getAdInfoDialogCss } from "../static/adInfo.js";
import getAdPositionFromScreenshot from "./helpers/getAdPositionFromScreenshot";
import { isLoggedIn } from "./ppid";
import excludedSites from "../static/excludedSitesFromTintin";

const tintin = {
  URL: `${ process.env.AD_REPORTER_URL }/report-ad`,
};

function sleepms(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function getScreenshots(callback) {
  navigator.mediaDevices.getDisplayMedia({ preferCurrentTab: true }).then((mediaStream) => {
    // sleep time expects milliseconds
    // must sleep 150ms. If only 100ms we get the sharescreen popup in picture.
    sleepms(150).then(() => {
      const mediaTracks = mediaStream.getVideoTracks();

      const framePromises = mediaTracks.map((track) => {
        const imageCaptureInstance = new ImageCapture(track);
        const frame = imageCaptureInstance.grabFrame();

        return frame;
      });
      Promise.all(framePromises).then((frames) => {
        // stop recording
        mediaTracks.forEach((track) => {
          track.stop();
        });
        return callback(frames);
      }).catch((err) => {
        throw new Error(err);
      });
    });
  })
  .catch((error) => {
    callback(null, error);
  });
}

function getHtmlForAd(slotId, type) {
  if (type === "Prebid") {
    const bid = pbjs
      .getAllWinningBids()
      .find((winningBid) => winningBid.adUnitCode === slotId);
    return bid && bid.ad;
  } else if (type === "IO") {
    const slot = getSlot(slotId);
    return slot && slot.getHtml();
  }
}

function getSlot(id) {
  return window.googletag
    .pubads()
    .getSlots()
    .find((slot) => slot.getSlotElementId() === id);
}

function slackPrompt(messageUrl) {
  if (confirm("Annonsen anmäld!\nTryck 'OK' för att gå vidare till #rapporterade-annonser i Slack")) {
    window.open(messageUrl);
  }
}

function sendSlackAdSlotReport(data) {
  const slackRequest = new XMLHttpRequest();
  slackRequest.open("POST", tintin.URL, true);

  slackRequest.setRequestHeader("Content-Type", "application/json");
  slackRequest.responseType = "json";

  slackRequest.onload = () => {
    const response = slackRequest.response;
    if (response && response.messageUrl) {
      slackPrompt(response.messageUrl);
    }
  };
  slackRequest.send(JSON.stringify(data));
}

function getClickHandler(params, ad) {
  return () => {
    if (!confirm("Du kommer anmäla denna annons till #rapporterade-annonser!")) return;
    const reason = prompt("Vad är orsaken till anmälan?");

    if (!reason || reason.length === 0) return alert("Annonsen ej anmäld! Du måste ange en orsak.");

    const reporter = prompt("Vad är ditt namn? (valfritt)");

    params.reason = reason;
    params.reporter = reporter;
    params.retrievedAt = new Date().toUTCString();
    params.url = window.location.href;
    params.adhtml = getHtmlForAd(params.id, params.type);

    if ("ImageCapture" in window) {
      const allowScreenshot = confirm("Kan vi ta en skärmdump på annonsen?");

      if (allowScreenshot) {
        const oldStyle = ad.parentNode.style;
        const oldParentStyle = ad.parentNode.parentNode.parentNode.style;
        ad.parentNode.style.border = "1rem solid rgb(141, 10, 231, 1)";
        ad.parentNode.style.padding = "0.4rem 0";
        ad.parentNode.parentNode.parentNode.style.margin = 0;

        getScreenshots((screenshots) => {
          if (screenshots) {
            const screenshotURLs = screenshots.map((screenshot) => {
              const screenCanvas = document.createElement("canvas");
              screenCanvas.width = screenshot.width;
              screenCanvas.height = screenshot.height;
              const screenContext = screenCanvas.getContext("2d");

              screenContext.drawImage(screenshot, 0, 0);

              const { topLeft, bottomRight } = getAdPositionFromScreenshot(screenshot, screenContext, screenCanvas);

              if (topLeft.x && topLeft.y && bottomRight.x && bottomRight.y && bottomRight.x > topLeft.x && bottomRight.y > topLeft.y) {
                const adImageData = screenContext.getImageData(topLeft.x, topLeft.y, (bottomRight.x - topLeft.x), (bottomRight.y - topLeft.y));
                const adCanvas = document.createElement("canvas");
                adCanvas.width = (bottomRight.x - topLeft.x);
                adCanvas.height = (bottomRight.y - topLeft.y);

                const adContext = adCanvas.getContext("2d");
                adContext.putImageData(adImageData, 0, 0);
                return adCanvas.toDataURL();
              }
              return "";
            });
            params.screenshotURLs = screenshotURLs;

          } else {
            // handle error. The user has not allowed screenshots. We don't need to do anything
          }
          ad.parentNode.style = oldStyle;
          ad.parentNode.parentNode.parentNode.style = oldParentStyle;
          sendSlackAdSlotReport(params);
        });
      } else {
        sendSlackAdSlotReport(params);
      }
    } else {
      sendSlackAdSlotReport(params);
    }
  };
}

function showConfirmation(ad) {
  let dialogModal;
  if (!document.getElementById("dialog-report-success-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getReportSuccessDialog();
    document.body.appendChild(dialogModal);

    const dialogReportButton = document.getElementById("dialog-success-report-button");
    const dialogCancelButton = document.getElementById("dialog-success-cancel-button");

    dialogModal.id = "dialog-report-success-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-report-success-modal") dialogModal.close();
    });
    dialogReportButton.onclick = () => dialogModal.close();
    dialogCancelButton.onclick = () => dialogModal.close();
  }
  dialogModal = document.getElementById("dialog-report-success-modal");
  dialogModal.showModal();

  const adInfoButton = ad.getElementsByClassName("ad-info_button")[0];
  const adSlot = getSlot(ad.id);
  const { height: adHeight, width: adWidth } = window.getComputedStyle(ad);
  adSlot.setCollapseEmptyDiv(false);
  window.googletag.destroySlots([adSlot]);
  ad.innerHTML = `
  <div style="height: ${adHeight}; padding: 64px 40px 64px 40px; box-sizing: border-box; width: ${adWidth}; background-color: rgb(211,211,211,1); z-index: 400; font-family: 'inter V', sans-serif; max-width: 1000px; max-height: 70vh; min-height: 380px;">
  <h2>Annonsen är rapporterad</h2>
  <p>Vi ser över den rapporterade  annonsen så snart som möjligt. Tills dess kan annonsen synas på andra artiklar. Den behöver då inte rapporteras igen.</p>
  <p>Tack för ditt deltagande!</p>
  </div>
  `;
  ad.onclick = () => {};
  adInfoButton.remove();
}

function sendTintinAdSlotReport(data, ad) {
  showConfirmation(ad);
  const tintinRequest = new XMLHttpRequest();
  tintinRequest.open("POST", tintin.URL, true);

  tintinRequest.setRequestHeader("Content-Type", "application/json");
  tintinRequest.responseType = "json";
  tintinRequest.send(JSON.stringify(data));
}

function submitReportHandler(params, ad) {
  if ("ImageCapture" in window && params.type === "AdX") {
    let oldStyle;
    if (ad.parentNode.tagName === "MAIN"
    || ad.parentNode.classList.contains("site-body__columns")
    || ad.parentNode.classList.contains("outsider-ads__sticky-context")
    || ad.parentNode.classList.contains("outsider-ad")
    || ad.parentNode.classList.contains("site-body__column-3")
    ) {
      oldStyle = ad.style;
      ad.style.border = "1rem solid rgb(141, 10, 231, 1)";
      ad.style.padding = "0.4rem 0";
    } else if (
      ad.parentNode.classList.contains("ad-fullpage")
      || ad.parentNode.classList.contains("fullpage-ad")
      || ad.parentNode.classList.contains("slot-rich_media_premium")
      || ad.parentNode.classList.contains("ad--rich_media_premium")

    ) {
      oldStyle = ad.firstChild.style;
      ad.firstChild.style.border = "1rem solid rgb(141, 10, 231, 1)";
      ad.firstChild.style.padding = "0.4rem 0";
    } else {
      oldStyle = ad.parentNode.style;
      ad.parentNode.style.border = "1rem solid rgb(141, 10, 231, 1)";
      ad.parentNode.style.padding = "0.4rem 0";
    }

    getScreenshots((screenshots, error) => {
      if (error) console.error(error);
      if (screenshots) {
        const screenshotURLs = screenshots.map((screenshot) => {
          const screenCanvas = document.createElement("canvas");
          screenCanvas.width = screenshot.width;
          screenCanvas.height = screenshot.height;
          const screenContext = screenCanvas.getContext("2d");

          screenContext.drawImage(screenshot, 0, 0);

          const { topLeft, bottomRight } = getAdPositionFromScreenshot(screenshot, screenContext, screenCanvas);

          if (topLeft.x && topLeft.y && bottomRight.x && bottomRight.y && bottomRight.x > topLeft.x && bottomRight.y > topLeft.y) {
            const adImageData = screenContext.getImageData(topLeft.x, topLeft.y, (bottomRight.x - topLeft.x), (bottomRight.y - topLeft.y));
            const adCanvas = document.createElement("canvas");
            adCanvas.width = (bottomRight.x - topLeft.x);
            adCanvas.height = (bottomRight.y - topLeft.y);

            const adContext = adCanvas.getContext("2d");
            adContext.putImageData(adImageData, 0, 0);
            return adCanvas.toDataURL();
          }
          return "";
        });
        params.screenshotURLs = screenshotURLs;
        if (ad.parentNode.style.border === "1rem solid rgb(141, 10, 231)") {
          ad.parentNode.style = oldStyle;
        } else if (ad.firstChild.style.border === "1rem solid rgb(141, 10, 231)") {
          ad.firstChild.style = oldStyle;
        } else {
          ad.style = oldStyle;
        }
        sendTintinAdSlotReport(params, ad);
      } else {
        // handle error. The user has not allowed screenshots. We don't need to do anything. We don't send the report.
        if (ad.parentNode.style.border === "1rem solid rgb(141, 10, 231)") {
          ad.parentNode.style = oldStyle;
        } else if (ad.firstChild.style.border === "1rem solid rgb(141, 10, 231)") {
          ad.firstChild.style = oldStyle;
        } else {
          ad.style = oldStyle;
        }
      }

    });
  } else {
    sendTintinAdSlotReport(params, ad);
  }
}
function reportButtonClick(params, ad) {
  let dialogModal;
  if (!document.getElementById("dialog-report-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getAdInfoReportDialog();
    document.body.appendChild(dialogModal);

    dialogModal.id = "dialog-report-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-report-modal") dialogModal.close();
    });
    const dialogCancelButton = document.getElementById("dialog-report-cancel-button");
    dialogCancelButton.onclick = () => dialogModal.close();
  }
  dialogModal = document.getElementById("dialog-report-modal");

  const dialogReportForm = document.getElementById("dialog-report-form");
  dialogReportForm.onsubmit = () => {
    // formMethod = dialog closes the dialog when the form is submitted.
    params.reason = dialogModal.querySelector("input[name=reason]:checked").value;
    submitReportHandler(params, ad);
  };

  const screenshotInfoText = document.getElementById("ad-info_dialog_screenshot_info");
  const reportButton = document.getElementById("dialog-report-submit-button");

  if ("ImageCapture" in window && params.type === "AdX") {
    screenshotInfoText.hidden = false;
    reportButton.textContent = "Nästa";
  } else {
    screenshotInfoText.hidden = true;
    reportButton.textContent = "Skicka";
  }
  dialogModal.showModal();
}

function adInfoClick(params, ad, config) {
  params.retrievedAt = new Date().toUTCString();
  params.url = window.location.href;
  params.adhtml = getHtmlForAd(params.id, params.type);
  if (params.type === "Prebid") params.prebidData = pbjs.getAllWinningBids().find((winningBid) => winningBid.adUnitCode === params.id);

  appendDialogStyleSheet();
  ad.scrollIntoView({ behavior: "smooth", block: "center" });
  let dialogModal;
  if (!document.getElementById("dialog-modal")) {
    dialogModal = document.createElement("dialog");
    dialogModal.innerHTML = getAdInfoDialog();


    dialogModal.id = "dialog-modal";
    dialogModal.className = "ad-info_dialog-modal";
    dialogModal.ariaLabel = "Annonsinformation och rapportering";
    dialogModal.ariaDescription = "Annonsinformation och rapportering";

    dialogModal.addEventListener("click", (event) => {
      if (event.target.id === "dialog-modal") dialogModal.close();
    });
    document.body.appendChild(dialogModal);
    const dialogCancelButton = document.getElementById("dialog-cancel-button");
    const reportSection = document.getElementById("ad-info_dialog-section-report");
    dialogCancelButton.onclick = () => dialogModal.close();

    if (!isLoggedIn(config) && !config.isInternal) reportSection.style.display = "none";
  }
  dialogModal = document.getElementById("dialog-modal");
  const dialogReportButton = document.getElementById("dialog-report-button");

  dialogReportButton.onclick = () => {
    dialogModal.close();
    reportButtonClick(params, ad);
  };
  dialogModal.showModal();

}

function appendDialogStyleSheet() {
  const styleTag = document.createElement("style");
  const cssString = getAdInfoDialogCss();

  styleTag.appendChild(document.createTextNode(cssString));
  window.document.head.appendChild(styleTag);
}

function gatherInformation(adUnit, adElement, bauConfig, cb) {
  const slot = getSlot(adUnit.id);
  const responseInformation = slot.getResponseInformation();

  const adInfo = {
    id: adUnit.id,
    campaignId: adUnit.adData.campaignId,
    creativeId: adUnit.adData.creativeId,
    lineItemId: adUnit.adData.lineItemId,
    device: bauConfig.device,
    googleQueryId: adElement.dataset.googleQueryId,
    path: adUnit.adData.path,
    size: `${adUnit.size.width}x${adUnit.size.height}`,
  };

  if (window.pbjs?.que && window.pbjs?.getAllWinningBids) {
    // Reason for a timeout is that we have to let Prebid set that there has been a winning bid.
    setTimeout(() => {
      if (responseInformation.isBackfill) {
        adInfo.type = "AdX";
        adInfo.creativeId = responseInformation.sourceAgnosticCreativeId;
        adInfo.lineItemId = responseInformation.sourceAgnosticLineItemId;
        adInfo.yieldGroupId = responseInformation.yieldGroupIds && responseInformation.yieldGroupIds[0];
      } else if (pbjs.getAllWinningBids().find((bid) => bid.adUnitCode === adInfo.id)) {
        adInfo.type = "Prebid";
        adInfo.prebidData = pbjs.getAllWinningBids().find((bid) => bid.adUnitCode === adInfo.id);
      } else {
        adInfo.type = "IO";
      }

      return cb(adInfo);
    }, 200);
  } else {
    if (responseInformation.isBackfill) {
      adInfo.type = "AdX";
      adInfo.creativeId = responseInformation.sourceAgnosticCreativeId;
      adInfo.lineItemId = responseInformation.sourceAgnosticLineItemId;
      adInfo.yieldGroupId = responseInformation.yieldGroupIds && responseInformation.yieldGroupIds[0];
    } else {
      adInfo.type = "IO";
    }
    return cb(adInfo);
  }
}

function createReportButton(adUnit, bauConfig) {
  if (!excludedSites.find((it) => window.location.hostname.replace(/^www\./, "").match(it))) {
    if (adUnit.isEmpty) return;
    const ad = document.getElementById(adUnit.id);

    if (!ad || ad.getElementsByClassName("ad-info_button").length > 0) return;
    // We need the ad to be positioned in order to position the button relative to
    // it. If the site has not positioned the ad, we just make it relative.
    const computedStyle = window.getComputedStyle(ad);
    if (computedStyle.position === "" || computedStyle.position === "static") {
      ad.style.position = "relative";
    }
    gatherInformation(adUnit, ad, bauConfig, (adInfo) => {
      const adInfoButton = document.createElement("button");
      adInfoButton.className = "ad-info_button";
      adInfoButton.title = "Visa annonsinformation";
      adInfoButton.type = "button";
      adInfoButton.ariaLabel = "Visa annonsinformation";
      adInfoButton.ariaDescription = "Visa annonsinformation och rapportera annons";
      // adInfoButton svg is circle with an exclamation mark.
      adInfoButton.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"currentColor\"><path d=\"M8.73 13.56A1.13 1.13 0 0 0 9.9 12.4c0-.34-.12-.62-.34-.85-.22-.23-.5-.34-.83-.34-.33 0-.61.11-.83.34-.23.23-.34.51-.34.85a1.13 1.13 0 0 0 1.17 1.16Zm-1.1-3.7h2.2V4.57h-2.2v5.27Zm1.1 7.87a8.48 8.48 0 0 1-3.42-.69A8.84 8.84 0 0 1 .7 12.42 8.48 8.48 0 0 1 0 9 8.84 8.84 0 0 1 5.31.96 8.48 8.48 0 0 1 8.73.27a8.85 8.85 0 0 1 8.04 5.31c.46 1.06.69 2.2.69 3.42a8.85 8.85 0 0 1-5.31 8.04c-1.06.46-2.2.69-3.42.69Zm0-2.2a6.3 6.3 0 0 0 4.63-1.9A6.3 6.3 0 0 0 15.25 9a6.3 6.3 0 0 0-1.89-4.64 6.3 6.3 0 0 0-4.63-1.88 6.3 6.3 0 0 0-4.64 1.88A6.3 6.3 0 0 0 2.21 9a6.3 6.3 0 0 0 1.88 4.64 6.3 6.3 0 0 0 4.64 1.88Z\"/></svg>";
      adInfoButton.onclick = () => adInfoClick(adInfo, ad, bauConfig);

      ad.appendChild(adInfoButton);
    });
  } else {
    if (bauConfig.isInternal) {
      if (adUnit.isEmpty) return;

      const adWrapper = document.getElementById(adUnit.id);

      if (!adWrapper) return;

      const ad = !adWrapper.firstChild.innerHTML ? adWrapper : adWrapper.firstChild;

      // We need the ad to be positioned in order to position the button relative to
      // it. If the site has not positioned the ad, we just make it relative.
      const computedStyle = window.getComputedStyle(ad);
      if (computedStyle.position === "" || computedStyle.position === "static") {
        ad.style.position = "relative";
      }

      gatherInformation(adUnit, adWrapper, bauConfig, (adInfo) => {
        const adReportButton = document.createElement("button");
        adReportButton.innerHTML = "📣";
        adReportButton.title = "Rapportera annons";
        adReportButton.onclick = getClickHandler(adInfo, ad);
        adReportButton.classList.add("ad__report-button");

        ad.appendChild(adReportButton);
      });
    }
  }
}

export {
  createReportButton
};

