import { slotNames } from "../static/slotNames";

function parseEvent(evt, type) {
  const { slotName: name, slotNameNumber: number, slotId: id, adUnitPath: path, reportKey } = getSlotParams(evt.slot);
  const { campaignId, creativeId, lineItemId } = getAdData(evt, type);
  const prebidAd = isPrebidAd(id);

  // Prebid ads also have a 1x1 pixel size, other 1x1 ads are "blocking" pixels to block out other ads
  const isEmpty = (!prebidAd && isBlockingPixel(evt.size)) || evt.isEmpty;

  const eventData = {
    name,
    number,
    id,
    isEmpty,
    size: {
      width: 0,
      height: 0
    },
    adData: {
      prebidAd,
      campaignId,
      creativeId,
      lineItemId,
      path,
      reportKey
    }
  };

  const isRichMedia = eventData.name === slotNames.RICH_MEDIA_PREMIUM || eventData.name === slotNames.MOB_RICH_MEDIA_PREMIUM;

  if (prebidAd) {
    const sizeTargeting = evt.slot.getTargeting("hb_size");
    if (sizeTargeting && sizeTargeting.length > 0) {
      const [ width, height ] = sizeTargeting[0].split("x");
      eventData.size = {
        width: parseInt(width, 10),
        height: parseInt(height, 10)
      };
    }
  } else if (evt.size) {
    eventData.size = {
      "width": evt.size[0],
      "height": evt.size[1]
    };
  }
  eventData.adData.responsive = isResponsive(eventData.size, isRichMedia, evt.isEmpty);

  return eventData;
}

function isPrebidAd(id) {
  if (!window.pbjs || !window.pbjs?.getAllWinningBids) return false;
  return !!window.pbjs.getAllWinningBids().find((bid) => bid.adUnitCode === id);
}

function getSlotParams(slot) {
  const slotName = slot.getTargeting("slotName")[0];
  const slotNameNumber = slot.getTargeting("slotNameNo")[0];
  const slotId = slot.getSlotElementId();
  const adUnitPath = slot.getAdUnitPath();
  const reportKey = slot.getTargeting("reportKey")[0];
  return { slotName, slotNameNumber, slotId, adUnitPath, reportKey };
}

function isBlockingPixel(sizes) {
  // NOTE width and height === 0 is used for fluid materials
  return sizes && sizes[0] === 1 && sizes[1] === 1;
}

function getAdData(evt, type) {
  let campaignId, creativeId, lineItemId;
  if (type === "slotRenderEnded") {
    campaignId = evt.campaignId;
    creativeId = evt.creativeId;
    lineItemId = evt.lineItemId;
  } else if (type === "impressionViewable") {
    const responseInformation = evt.slot.getResponseInformation();
    campaignId = responseInformation.campaignId;
    creativeId = responseInformation.creativeId;
    lineItemId = responseInformation.lineItemId;
  }
  return {
    campaignId, creativeId, lineItemId
  };
}

function isResponsive(size, isRichMedia, isEmpty) {
  const isFluid = !isEmpty && size.width === 0 && size.height === 0;

  return (size.width === 50 && size.height === 50) || (isRichMedia && isFluid);
}

export {
  parseEvent
};
