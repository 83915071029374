import { addTimeoutId } from "./index.js";

function prebidScriptExists() {
  // we either check if the prebid script is loaded or if we have concent for the magnite vendor
  return !!document.querySelector("[src*='rubiconproject.com/prebid'][type='text/javascript']") || (window.Didomi && window.Didomi.getUserStatus().vendors.global.enabled.includes(52));
}

function prebidCallback(slot, slotId, prebidTimeoutID, callback) {
  if (prebidTimeoutID) clearTimeout(prebidTimeoutID);
  window.googletag = window.googletag || {};
  googletag.cmd = googletag.cmd || [];
  if (pbjs.adserverRequestSent[slotId]) return;
  pbjs.adserverRequestSent[slotId] = true;
  googletag.cmd.push(() => {
    pbjs.que.push(() => {
      slot.prebidStatus = "done";
      slot.slot.setTargeting("demandmanager", 1);
      callback([slot]);
    });
  });
}

function setUpPrebid(slot, prebidConfig, prebidData, callback) {
  if (!prebidScriptExists() && !window.pbjs) return callback([slot]);

  window.pbjs = window.pbjs || {};
  pbjs.que = pbjs.que || [];
  pbjs.adserverRequestSent = pbjs.adserverRequestSent || {};

  const prebidTimeout = parseInt(prebidConfig.prebidTimeout) || 2000;
  const failsafeTimeout = prebidTimeout + 1000;

  const slotId = slot.slot.getSlotElementId();

  const prebidTimeoutID = setTimeout(() => {
    prebidCallback(slot, slotId, null, callback);
  }, failsafeTimeout);

  addTimeoutId(prebidTimeoutID);

  pbjs.que.push(() => {
    slot.prebidStatus = "in-progress";
    pbjs.rp.requestBids({
      gptSlotObjects: [slot.slot],
      data: prebidData,
      timeout: prebidTimeout,
      callback: () => {
        prebidCallback(slot, slotId, prebidTimeoutID, callback);
      }
    });
  });

}

export {
  setUpPrebid,
};
