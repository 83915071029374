const enums = {
  BORDER_COLOR: {
    r: 132,
    g: 0,
    b: 222,
    a: 255
  },
  BORDER_COLOR_ALT: {
    r: 28,
    g: 24,
    b: 39,
    a: 255
  }
};

function topBorder(i, d) {
  const fiftyPixels = 200;
  return (
    d[i - fiftyPixels] === enums.BORDER_COLOR.r &&
    d[i - fiftyPixels + 1] === enums.BORDER_COLOR.g &&
    d[i - fiftyPixels + 2] === enums.BORDER_COLOR.b &&
    d[i - fiftyPixels + 3] === enums.BORDER_COLOR.a
  ) || (
    d[i - fiftyPixels] === enums.BORDER_COLOR_ALT.r &&
    d[i - fiftyPixels + 1] === enums.BORDER_COLOR_ALT.g &&
    d[i - fiftyPixels + 2] === enums.BORDER_COLOR_ALT.b &&
    d[i - fiftyPixels + 3] === enums.BORDER_COLOR_ALT.a
  );
}

function bottomBorder(i, d) {
  const fiftyPixels = 200;
  return (
    d[i + fiftyPixels] === enums.BORDER_COLOR.r &&
    d[i + fiftyPixels + 1] === enums.BORDER_COLOR.g &&
    d[i + fiftyPixels + 2] === enums.BORDER_COLOR.b &&
    d[i + fiftyPixels + 3] === enums.BORDER_COLOR.a
  ) || (
    d[i + fiftyPixels] === enums.BORDER_COLOR_ALT.r &&
    d[i + fiftyPixels + 1] === enums.BORDER_COLOR_ALT.g &&
    d[i + fiftyPixels + 2] === enums.BORDER_COLOR_ALT.b &&
    d[i + fiftyPixels + 3] === enums.BORDER_COLOR_ALT.a
  );
}


function nextToLeftBorder(i, d) {
  const pixelWidth = 4;
  return (
    d[i - 5 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 - 5 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 - 5 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 - 5 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i - 4 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 - 4 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 - 4 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 - 4 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i - 3 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 - 3 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 - 3 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 - 3 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i - 2 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 - 2 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 - 2 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 - 2 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i - 1 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 - 1 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 - 1 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 - 1 * pixelWidth] === enums.BORDER_COLOR.a
    ) || (
    d[i - 5 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 - 5 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 - 5 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 - 5 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i - 4 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 - 4 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 - 4 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 - 4 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i - 3 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 - 3 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 - 3 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 - 3 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i - 2 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 - 2 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 - 2 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 - 2 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i - 1 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 - 1 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 - 1 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 - 1 * pixelWidth] === enums.BORDER_COLOR_ALT.a
  );
}

function nextToRightBorder(i, d) {
  const pixelWidth = 4;
  return (
    d[i + 1 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 + 1 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 + 1 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 + 1 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i + 2 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 + 2 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 + 2 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 + 2 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i + 3 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 + 3 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 + 3 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 + 3 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i + 4 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 + 4 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 + 4 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 + 4 * pixelWidth] === enums.BORDER_COLOR.a &&
    d[i + 5 * pixelWidth] === enums.BORDER_COLOR.r && d[i + 1 + 5 * pixelWidth] === enums.BORDER_COLOR.g && d[i + 2 + 5 * pixelWidth] === enums.BORDER_COLOR.b && d[i + 3 + 5 * pixelWidth] === enums.BORDER_COLOR.a
    ) || (
    d[i + 1 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 + 1 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 + 1 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 + 1 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i + 2 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 + 2 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 + 2 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 + 2 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i + 3 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 + 3 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 + 3 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 + 3 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i + 4 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 + 4 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 + 4 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 + 4 * pixelWidth] === enums.BORDER_COLOR_ALT.a &&
    d[i + 5 * pixelWidth] === enums.BORDER_COLOR_ALT.r && d[i + 1 + 5 * pixelWidth] === enums.BORDER_COLOR_ALT.g && d[i + 2 + 5 * pixelWidth] === enums.BORDER_COLOR_ALT.b && d[i + 3 + 5 * pixelWidth] === enums.BORDER_COLOR_ALT.a
  );
}

function isBorder(i, d) {
  return (
    d[i] === enums.BORDER_COLOR.r &&
    d[i + 1] === enums.BORDER_COLOR.g &&
    d[i + 2] === enums.BORDER_COLOR.b &&
    d[i + 3] === enums.BORDER_COLOR.a
  ) || (
    d[i] === enums.BORDER_COLOR_ALT.r &&
    d[i + 1] === enums.BORDER_COLOR_ALT.g &&
    d[i + 2] === enums.BORDER_COLOR_ALT.b &&
    d[i + 3] === enums.BORDER_COLOR_ALT.a
  );
}

function getAdPositionFromScreenshot(screenshot, screenContext, screenCanvas) {
  const screenshotData = screenContext.getImageData(0, 0, screenCanvas.width, screenCanvas.height);

  const d = screenshotData.data;
  const topLeft = {};
  const bottomRight = {};

  for (let i = 200; i < d.length; i += 4) {

    if (!topLeft.x && !topBorder(i, d) && nextToLeftBorder(i, d) && !isBorder(i, d)) {

      // the remainder is how many colours (1px = 4colours) into the picture we are
      topLeft.x = ((i) % (screenshot.width * 4)) / 4;
      // the fraction is how many rows down we are
      topLeft.y = Math.floor((i) / (screenshot.width * 4)) + 1;
    }

    if (topLeft.x && !bottomBorder(i, d) && nextToRightBorder(i, d) && !isBorder(i, d)) {
      bottomRight.x = ((i ) % (screenshot.width * 4)) / 4;
      bottomRight.y = Math.floor((i) / (screenshot.width * 4)) + 1;
    }

    // if we're out of the picture, stop scanning.
    if (topLeft.x && nextToRightBorder(i, d) && bottomBorder(i, d)) {
      break;
    }
  }

  if (bottomRight.x < topLeft.x) {
    bottomRight.x = parseInt(screenshot.width);
  }

  return { topLeft, bottomRight };
}
export default getAdPositionFromScreenshot;
