const throttling = 70;
const queue = [];
let active = false;

function addListener(object) {
  if (!active) {
    active = true;
    window.addEventListener("scroll", throttle(onScroll));
  }
  queue.push(object);
}

function removeListener(name) {
  queue.some((fn, index) => {
    if (fn.name === name) {
      queue.splice(index, 1);
      return true;
    }
    return false;
  });
}

function onScroll() {
  queue.forEach((fn) => {
    fn.callback();
  });
}

function throttle(fn) {
  let last;
  let deferTimer;
  return function () {
    const now = Date.now();
    const args = arguments;
    if (last && now < last + throttling) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(null, args);
      }, throttling);
    } else {
      last = now;
      fn.apply(null, args);
    }
  };
}

export {
  addListener,
  removeListener
};
