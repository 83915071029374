import { setCookie, getCookie } from "./cookieHelper";
import targetHelper from "./targetHelper";
import getJSON from "../getJSON";

function setDeliveranceSegmentsCookie() {
	const clientId = getCookie("bnacid");
	if (!clientId) return;

  const url = `https://elmer.bonniernews.se/v1/segment/${clientId}?purpose=personalized-ads`;

  getJSON(url, (err, res) => {
    if (err) return;

    const segmentsIds = res.marketing_segments;
    const segmentsString = segmentsIds.join(",");

    setCookie("bau_deliverance", segmentsString, 7, undefined, "Strict");

    const googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      targetHelper.update({bi: segmentsIds});
    });
  });
}


function getReynoldsId() {
  const segments = getSegments();
  if (segments && segments.includes("BI2018")) return null;
	const clientId = getCookie("bnacid");

  return clientId;
}

function getSegments() {
  const segments = getCookie("bau_deliverance");
  return segments ? segments.split(",") : null;
}

export {
  setDeliveranceSegmentsCookie,
  getSegments,
  getReynoldsId
};
