function getCookie(cookieName) {
  try {
    if (!window || !window.top || !window.top.document || !window.top.document.cookie) {
      return null;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn("Could not access window.top.document.cookie: ", e);
    return null;
  }

  const cookies = window.top.document.cookie.split(";");
  let cookieValue;

  cookies.forEach((cookie) => {
    const name = cookie.substr(0, cookie.indexOf("="));
    if (name.trim() === cookieName) {
      cookieValue = cookie.substr(cookie.indexOf("=") + 1);
    }
  });

  if (cookieValue) {
    return decodeURIComponent(cookieValue.trim());
  }
  return null;
}

function setCookie(cookieName, value, expDays, domainName, sameSite) {
  let cookieValue = encodeURIComponent(value);
  if (typeof expDays === "number") {
    const expDate = new Date();
    expDate.setTime(expDate.getTime() + expDays * 24 * 60 * 60 * 1000);
    cookieValue += `; expires=${expDate.toUTCString()}`;
  }

  if (typeof domainName === "string") {
    if (!/^\./.test(domainName)) {
      domainName = `.${domainName}`;
    }
    cookieValue += `; domain=${domainName}`;
  }

  if (typeof sameSite === "string") {
    cookieValue += `; SameSite=${sameSite}`;
  }

  window.top.document.cookie = `${cookieName}=${cookieValue};path=/;Secure`;
}

export {
  getCookie,
  setCookie
};
