import getJSON from "../../plugins/getJSON";
import targetHelper from "./targetHelper";

function setBongeoTargeting({ bongeoUrl: baseUrl, userCoords }) {
  appendCurrentPositionToUrl(baseUrl, (url) => {
    if (baseUrl === url && Number.isFinite(userCoords.latitude) && Number.isFinite(userCoords.longitude)) {
      url = `${url}?latitude=${userCoords.latitude}&longitude=${userCoords.longitude}`;
    }
    getJSON(url, (err, data) => {
      if (err || !data) return;

      window.googletag = window.googletag || {};
      googletag.cmd = googletag.cmd || [];

      const { bongeoCode, bongeoRegionCode, bongeoLanCode } = data;

      googletag.cmd.push(() => {
        targetHelper.update({
          bontarg: [bongeoCode, bongeoRegionCode, bongeoLanCode],
        });
      });
    });
  });
}

function appendCurrentPositionToUrl(baseUrl, callback) {
  if (
    window.navigator &&
    window.navigator.geolocation &&
    window.navigator.permissions
  ) {
    window.navigator.permissions
      .query({ name: "geolocation" })
      .then((result) => {
        if (result.state === "granted") {
          window.navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;

            return callback(
              `${baseUrl}?latitude=${latitude}&longitude=${longitude}`
            );
          });
        } else {
          return callback(baseUrl);
        }
      })
      .catch(() => {
        callback(baseUrl);
      });
  } else {
    return callback(baseUrl);
  }
}

export { setBongeoTargeting };
