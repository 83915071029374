let refreshTimeout = null;
let url = window.location.href;

function refreshAds(definedSlots, bundledSlots) {
  if (!definedSlots || definedSlots.length === 0 || !definedSlots.map) return;
  if (refreshTimeout) clearTimeout(refreshTimeout);
  const newSlots = definedSlots.map((definedSlot) => definedSlot.slot);
  bundledSlots.push(...newSlots);

  if (bundledSlots.length >= 10) {
    refresh(bundledSlots);
  } else {
    refreshTimeout = setTimeout(() => {
      refresh(bundledSlots);
    }, 50);
  }
}

function refresh(bundledSlots) {
  let changeCorrelator = false;

  if (url !== window.location.href) {
    url = window.location.href;
    changeCorrelator = true;
  }

  const toLoad = bundledSlots.splice(0);

  while (toLoad.length) {
    googletag.pubads().refresh(toLoad.splice(0, 10), { changeCorrelator });
    changeCorrelator = false;
  }
}

export { refreshAds };
