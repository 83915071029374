import { hasDuplicates } from "./helpers/arrayHelper";
import * as passbackHelper from "./helpers/passbackHelper";

let bauDefinedSlots = [];
let bauConfig;

function initPassback(slotId, lineItemIds) {
  const passbackLimit = 10;

  if (lineItemIds.length > passbackLimit || hasDuplicates(lineItemIds)) return;

  definePassbackSlot(slotId, lineItemIds);
}

function definePassbackSlot(slotId, lineItemIds) {
  const { definedSlot, definedSlotIndex } = getDefinedSlot(slotId);

  const slotTargeting = getAllTargeting(definedSlot.slot);
  const slotName = slotTargeting.slotName[0];
  const slotNameNumber = parseInt(slotTargeting.slotNameNo[0]);
  const slotSizes = getSizes(slotName, slotNameNumber);
  const passbackAdUnitPath = passbackHelper.getPassbackPath(bauConfig.path);

  definedSlot.slot.addService(googletag.pubads()).setCollapseEmptyDiv(false);

  const slotElement = window.document.getElementById(slotId) || window.top.document.getElementById(slotId);

  if (!slotElement.style.minHeight) {
    passbackHelper.resize(slotElement);
  }

  googletag.destroySlots([definedSlot.slot]);

  const passbackSlot = googletag.defineSlot(passbackAdUnitPath, slotSizes, slotId)
    .addService(googletag.pubads())
    .setTargeting("passback", lineItemIds);

  setAllTargeting(passbackSlot, slotTargeting);

  if (!bauConfig.disableCollapse) {
    passbackSlot.addService(googletag.pubads()).setCollapseEmptyDiv(true, false);
  }

  googletag.display(slotId);

  bauDefinedSlots[definedSlotIndex].slot = passbackSlot;

  googletag.pubads().refresh([passbackSlot], { changeCorrelator: false });
}

function getDefinedSlot(slotId) {
  let definedSlot, definedSlotIndex;

  bauDefinedSlots.some((element, index) => {
    if (element.slot.getSlotElementId() === slotId) {
      definedSlot = element;
      definedSlotIndex = index;

      return true;
    }
    return false;
  });

  return { definedSlot, definedSlotIndex };
}

function getAllTargeting(slot) {
  const targeting = {};
  const targetingKeys = slot.getTargetingKeys();

  targetingKeys.forEach((key) => {
    if (key === "passback") return;
    targeting[key] = slot.getTargeting(key);
  });

  return targeting;
}

function setAllTargeting(slot, targeting) {
  Object.keys(targeting).forEach((key) => {
    slot.setTargeting(key, targeting[key]);
  });
}

function getSizes(slotName, slotNameNumber) {
  const slots = bauConfig.slotNameConfig[slotName].slots;

  if (slotNameNumber > slots.length) {
    return slots[0].sizes;
  } else {
    return slots[slotNameNumber - 1].sizes;
  }
}

function setBauConfig(config) {
  bauConfig = config;
  bauDefinedSlots = [];
}

function addDefinedSlot(definedSlots) {
  bauDefinedSlots = bauDefinedSlots.concat(definedSlots);
}


export {
  setBauConfig,
  addDefinedSlot,
  initPassback
};
