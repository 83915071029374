import { stripUrl } from "./urlHelper.js";

function convertSpaBam(spaBamData, hostname) {
  const url = getConfigUrl(hostname);

  const baseNode = getBaseNode(spaBamData, url, true);

  return convertBamConfig(baseNode, spaBamData.config);
}

function getBaseNode(spaBamData, url, firstCall) {
    const { config } = spaBamData;
    const configKeys = Object.keys(config);

    let baseNode;
    configKeys.some((configKey) => {
        if (config[configKey].url === url) {
          baseNode = config[configKey];
          return true;
        }
    });

    if (!baseNode) {
      const parentUrl = getParentUrl(url);
      if (!parentUrl) {
        return null;
      }
      return getBaseNode(spaBamData, getParentUrl(url), false);
    }

    const node = Object.assign({}, baseNode);

    if (firstCall && node.frontPage) {
      node.path = `${baseNode.path}/frontpage`;
    }

    node.adInfo = spaBamData.adInfo;
    node.frequencyCookie = spaBamData.frequencyCookie;

    return node;
}

function getConfigUrl(hostname) {
  hostname = hostname ? hostname : window.location.hostname;
  const url = `${hostname}${window.location.pathname}`;
  return stripUrl(url);
}

function getParentUrl(url) {
  let urlParts = url.split("/");
  if (urlParts.length < 1) return;
  urlParts = urlParts.slice(0, urlParts.length - 1);
  return urlParts.join("/");
}

function convertBamConfig(baseNode, config) {
  const result = {
    "url": baseNode.url,
    "adsEnabled": baseNode.adsEnabled,
    "slotNameConfig": baseNode.slotNameConfig || getInheritedConfig(config, baseNode.inheritSlotNameConfigParentId, "slotNameConfig"),
    "prebidConfig": baseNode.prebidConfig || getInheritedConfig(config, baseNode.inheritPrebidConfigParentId, "prebidConfig"),
    "path": baseNode.path || getInheritedConfig(config, baseNode.inheritPathParentId, "path"),
    "adInfo": baseNode.adInfo,
    "frequencyCookie": baseNode.frequencyCookie,
    "spa": true
  };

  return result;
}

function getInheritedConfig(config, nodeId, propName) {
 return config[nodeId][propName];
}

export {
  convertSpaBam
};
