function getUserAdsConsent(consentStatus) {
  return (
    (consentStatus.vendors.global.enabled.includes("google") || consentStatus.vendors.legitimate_interest.enabled.includes("google")) &&
    (
      consentStatus.vendors.global.enabled.includes("c:bonniernews") || consentStatus.vendors.legitimate_interest.enabled.includes("c:bonniernews") ||
      consentStatus.vendors.global.enabled.includes("c:bn-tailsweep-bonniernews") ||
      consentStatus.vendors.global.enabled.includes("c:bn-netdoktor-bonniernews") ||
      consentStatus.vendors.global.enabled.includes("c:bn-netdoktorpro-bonniernews")
    ) &&
    [
      "select_basic_ads",
      "measure_ad_performance",
      "market_research",
      "improve_products",
    ].every((purpose) =>
      consentStatus.purposes.global.enabled.includes(purpose) || consentStatus.purposes.legitimate_interest.enabled.includes(purpose)
    )
  );
}

function getDeliveranceConsent(consentStatus) {
  return (
    ( //netdoktor vendors and purposes
      consentStatus.vendors.global.enabled.includes("c:bn-netdoktor-bonniernews") &&
      consentStatus.purposes.global.enabled.includes("bn-netdoktor-firstpart")
    ) ||
    ( //netdoktorpro or bonnier
      consentStatus.vendors.global.enabled.includes("c:bonniernews") ||
      consentStatus.vendors.global.enabled.includes("c:bn-netdoktorpro-bonniernews")
      &&
      consentStatus.purposes.global.enabled.includes("marknadsfo-G6hNqgqm")
    )
  );
}

export { getUserAdsConsent, getDeliveranceConsent };
