import getJSON from "../getJSON";

function fetchConfigFromBam(hostname, deviceType, pageType, callback) {
  getJSON(buildBamUrl(hostname, deviceType, pageType), (err, bamData) => {
    if (err) return callback(err);
    callback(null, bamData);
  });
}

function buildBamUrl(hostname, deviceType, pageType) {
  const baseUrl = "https://bam.bonad.io/";
  hostname = hostname ? hostname : window.location.hostname;
  const decodePath = decodeURIComponent(window.location.pathname);
  const query = {
    url: `${window.location.protocol}//${hostname}${decodePath}`,
    pageType: pageType,
    device: deviceType ? deviceType : ""
  };

  return baseUrl + generateQueryString(query);
}

function getSlotConfig(slotNameNumber, slots) {
  if (slotNameNumber > slots.length) {
    return slots[0];
  } else {
    return slots[slotNameNumber - 1];
  }
}

function generateQueryString(query) {
  const queryString = Object.keys(query)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
    .join("&");
  return `?${queryString}`;
}

export {
  fetchConfigFromBam,
  buildBamUrl,
  getSlotConfig
};
