const slotNames = {
    RICH_MEDIA_PREMIUM: "rich_media_premium",
    MOB_RICH_MEDIA_PREMIUM: "mob_rich_media_premium",
    PANORAMA_TOP: "panorama_top",
    PANORAMA: "panorama",
    BOTTOM: "bottom",
    MOB_BOTTOM: "mob_bottom",
    RICH_MEDIA_PANORAMAINSCREEN: "rich_media_panoramainscreen",
    RICH_MEDIA_TAKEOVER: "rich_media_takeover",
    RICH_MEDIA_WALLPAPER: "rich_media_wallpaper",
    SPECIAL_HOCKEYSTICK: "special_hockeystick",
    MOB: "mob"
};

export {
    slotNames
};
