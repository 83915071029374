import { addListener } from "./helpers/adScrollHelper";

const data = {};

function should(slotElement, offset, direction) {
  const slotRect = slotElement.getBoundingClientRect();
  const slotPosition = slotRect[direction];

  if (slotElement.style.display === "none") return true;
  return slotPosition >= offset;
}

function trackAd(slotElement, offset, direction, key, callback) {
  if (should(slotElement, offset, direction)) {
    callback();
    return;
  }
  let index = 0;

  if (!data[key]) {
    data[key] = {
      slots: {}
    };
  } else {
    const indexes = Object.keys(data[key].slots);
    index = parseInt(indexes[indexes.length - 1]) + 1;
  }

  data[key].slots[index] = {
    "element": slotElement,
    "cb": callback
  };

  if (index === 0) {
    addListener({
      name: key,
      callback: () => {
        Object.keys(data[key].slots).forEach((slotIndex) => {
          if (should(data[key].slots[slotIndex].element, offset, direction)) {
            data[key].slots[slotIndex].cb();
            remove(key, slotIndex);
          }
        });
      }
    });
  }
}

function remove(key, slotIndex) {
  delete data[key].slots[slotIndex];
}

export default trackAd;
