function isInternalNetwork(callback) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", "https://assets.bonad.io/test-vpn", true);
  xhr.timeout = 200;
  xhr.ontimeout = function () {
    callback(null, false);
  };
  xhr.onload = function () {
    if (xhr.responseText === "OK") {
      return callback(null, true);
    }
    callback(null, false);
  };
  xhr.onerror = function (err) {
    callback(err);
  };
  xhr.send(null);
}

export {
  isInternalNetwork
};

