const subscriptions = {};
let currentIndex = 0;

const definedMessages = [
  "resize",
  "collapse",
  "slotRenderEnded",
  "impressionViewable",
  "empty"
];

function subscribe(topic, callback) {
  assertDefinedMessage(topic);
  if (typeof callback !== "function") return;
  if (!subscriptions[topic]) {
    subscriptions[topic] = {};
  }
  const token = ++currentIndex;
  subscriptions[topic][token] = callback;
  return token;
}

function publish(topic, data) {
  assertDefinedMessage(topic);
  if (subscriptions[topic]) {
    Object.keys(subscriptions[topic]).forEach((token) => {
      setTimeout(subscriptions[topic][token].bind(null, data), 0);
    });
  }
}

function unsubscribe(token) {
  Object.keys(subscriptions).forEach((topic) => {
    delete subscriptions[topic][token];
  });
}

// To ensure we keep the defined message contracts
function assertDefinedMessage(topic) {
  if (definedMessages.indexOf(topic) === -1) {
    throw new Error(`No message defined of type: ${topic}`);
  }
}

function reset() {
  Object.keys(subscriptions).forEach((topic) => {
    Object.keys(subscriptions[topic]).forEach((token) => {
      delete subscriptions[topic][token];
    });
  });
}

export {
  subscribe,
  unsubscribe,
  publish,
  reset
};
