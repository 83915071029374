function getPassbackPath(path) {
  return `${path.slice(0, 10)}passback${path.slice(9)}`;
}

function resize(slotElement) {
  const slotIframe = slotElement.getElementsByTagName("iframe")[0];
  slotElement.style.minHeight = `${slotIframe.height}px`;
}

export {
  getPassbackPath,
  resize
};
