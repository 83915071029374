import {slotNames} from "../static/slotNames";

function triggerFluidVideo(adUnit) {
  if (!isFluidRichMedia(adUnit)) return;
  const richMediaPremiumAdSlot = window.document.getElementById(adUnit.id);
  const calculatedWith = calculateWidth(window.innerWidth, window.innerHeight);

  richMediaPremiumAdSlot.style.margin = "0 auto";
  richMediaPremiumAdSlot.style.width = `${calculatedWith}`;
}

function isFluidRichMedia(adUnit) {
  const isRichMediaPremium = adUnit.name === slotNames.RICH_MEDIA_PREMIUM;
  const isFluid = !adUnit.isEmpty && adUnit.size.width === 0 && adUnit.size.height === 0;
  return isRichMediaPremium && isFluid;
}

function calculateWidth(windowWidth, windowHeight) {
  const aspectRatio = 16 / 9;
  const bannerHeight = 50;
  const availableHeight = windowHeight - bannerHeight;

  if ((availableHeight * aspectRatio) >= windowWidth) {
    return `${windowWidth}px`;
  } else {
    return `${availableHeight * aspectRatio}px`;
  }
}

export default triggerFluidVideo;
