function getJSON(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  if (url && url.includes("elmer.bonniernews.se")) {
    xhr.setRequestHeader("x-bn-origin", "bau");
  }
  xhr.responseType = "json";
  xhr.onload = function () {
    const status = xhr.status;
    if (status === 200) {
      let response = xhr.response;
      // polyfill for IE11
      if (typeof response === "string") {
        response = JSON.parse(response);
      }
      callback(null, response);
      return;
    } else {
      callback(status);
      return;
    }
  };
  xhr.send();
}

export default getJSON;
