function stripUrl(url) {
  url = removeProtocol(url);
  url = removeWWW(url);
  url = removeQueries(url);
  url = removeTrailingSlash(url);

  return url;
}

function removeProtocol(url) {
  return url.replace(/^https?:\/\//, "");
}

function removeWWW(url) {
  return url.replace(/^www\./, "");
}

function removeQueries(url) {
  return url.split("?")[0];
}

function removeTrailingSlash(url) {
  return url.replace(/\/$/, "");
}

export {
  stripUrl
};
