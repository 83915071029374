import getAdInfoCss from "../static/adInfoCss";

function appendAdInfoStyleSheet(content) {
  const styleTag = document.createElement("style");
  const cssString = getAdInfoCss(content);

  styleTag.type = "text/css";
  styleTag.appendChild(document.createTextNode(cssString));
  window.document.head.appendChild(styleTag);
}

function setAdInfoClickLink(element, link) {
  element.addEventListener("click", () => {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = link;
    newWindow.target = "_blank";
  });
  const googleDiv = element.firstElementChild;
  if (googleDiv) {
    googleDiv.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }
}

export {
  appendAdInfoStyleSheet,
  setAdInfoClickLink
};
