export default [
  /aktuellhallbarhet.se/,
  /byggindustrin.se/,
  /dagensmedia.se/,
  /dagenssamhalle.se/,
  /dagligvarunytt.se/,
  /fastighetsnytt.se/,
  /market.se/,
  /privataaffarer.se/,
  /resume.se/,
  /dagensmedicin.se/,
  /netdoktor.se/,
  /netdoktorpro.se/,
  /netlaakari.fi/,
  /di.se/,
  /dn.se/,
  /viivilla.se/,
  /iform.dk/,
  /bilmagasinet.dk/,
  /goerdetselv.dk/,
  /historienet.dk/,
  /idenyt.dk/,
  /bobedre.dk/,
  /illvid.dk/,
  /woman.dk/,
  /digitalfoto.dk/,
  /komputer.dk/,
  /iform.nu/,
  /gjoerdetselv.com/,
  /historienet.no/,
  /viivilla.no/,
  /bo-bedre.no/,
  /boligpluss.no/,
  /illvit.no/,
  /costume.no/,
  /tara.no/,
  /digital-foto.no/,
  /komputer.no/,
  /iform.se/,
  /digitalfotoforalla.se/,
  /gds.se/,
  /illvet.se/,
  /pctidningen.se/,
  /slakthistoria.se/,
  /varldenshistoria.se/,
  /teeitse.com/,
  /tieku.fi/,
  /kuntoplus.fi/,
  /historianet.fi/,
  /kotimikro.fi/,
  /digi-kuva.fi/,
  /wibnet.nl/,
  /historianet.nl/,
  /scienceillustrated.com/,
  /scandiliv.nl/,
];
