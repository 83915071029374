import {
  inlineGpt,
  init,
  preloadAds,
  displayAds,
  initiateDfpPassback,
  openConsole,
  events,
  refreshSlots,
  resetSlots
} from "./index";

inlineGpt();

function push(fn) {
  fn();
}

const bau = { init, preloadAds, displayAds, initiateDfpPassback, openConsole, events, push, refreshSlots, resetSlots };

if (window.Bau) {
  const queue = window.Bau;
  window.Bau = bau;

  queue.forEach((fn) => {
    fn();
  });
} else {
  window.Bau = bau;
}
