function hasDuplicates(array) {
    const uniqueItemArray = array.filter((item, index) => {
        return index === array.indexOf(item);
    });

    return array.length > uniqueItemArray.length;
}

export {
    hasDuplicates
};

