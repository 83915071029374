import { getCookie } from "./cookieHelper";
import { getSegments, getReynoldsId } from "./segmentsHelper";
import { queryParser } from "./queryHelper";

function init(config) {
  config = setThirdPartyTargeting(config);
  setTargeting("pageType", config.pageType);
  setTargeting("device", config.device);
  setTargetings(config.targeting);
  setTargeting("isExplicit", config.isExplicit);
  setTargeting("_ta_", getCookie("_ta_"));
  queryTargeting();
  urlTargeting();
}

function setThirdPartyTargeting(config) {
  if (config.disablePersonalizedAds) return config;
  config.targeting = config.targeting || {};

  const segments = getSegments();

  const reynoldsId = getReynoldsId();

  if (segments) config.targeting.bi = segments;
  if (reynoldsId) config.targeting.rc = reynoldsId;

  return config;
}

function queryTargeting() {
  const search = window.location.search;
  if (!search) return;

  const queryTargetings = queryParser(search);
  setTargetings(queryTargetings);
}

function urlTargeting() {
  const url = `${window.location.hostname}${window.location.pathname}`;
  const urlParts = url.replace(/-/g, "/").split("/");
  setTargeting("url", url);
  setTargeting("urlParts", urlParts);
}

function setTargetings(targeting) {
  if (!targeting) return;
  Object.keys(targeting).forEach((key) => {
    setTargeting(key, targeting[key]);
  });
}

function setTargeting(key, value) {
  if (!value) return;
  if (typeof value === "number" || typeof value === "boolean") {
    value = value.toString();
  }

  // If whitespace is present, google uses it *literally* with the value for targeting, so we remove it
  if (Array.isArray(value)) {
    value = value.map((v) => v.replace(/\s/g, ""));
  } else {
    value = value.replace(/\s/g, "");
  }

  googletag.pubads().setTargeting(key, value);
}

export default {
  init,
  update: setTargetings,
};
