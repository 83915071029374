import { getCookie } from "./helpers/cookieHelper";
import { getRolledHash } from "./helpers/rollingHash";

function getPublisherProvidedId(config, callback) {
  const id = getUserIdFromIdToken("idtoken") || getUserIdFromIdToken("bnidtoken") || getUserIdFromIdToken("bnidtoken-lab") || getUserIdFromLocalStorage() || getUserIdFromCookie() || config.ppid;

  if (!id) return callback(null, null);

  getRolledHash(`bn-ppid:${id}`, callback);
}

function isLoggedIn(config) {
  const id = getUserIdFromIdToken("idtoken") || getUserIdFromIdToken("bnidtoken") || getUserIdFromIdToken("bnidtoken-lab") || getUserIdFromLocalStorage() || getUserIdFromCookie() || config.ppid;
  return id;
}

// Local has the common idtoken and it's called "bnidtoken" there
function getUserIdFromIdToken(name) {
  try {
    const idtoken = getCookie(name);

    if (!idtoken) return null;

    // idtoken is a JWT. While it'd be possible to verify the
    // payload with the public key, we are not that afraid of
    // tampering. We just need the ID.
    // eslint-disable-next-line no-unused-vars
    const [header, payload, signature] = idtoken.split(".");

    const userInfo = JSON.parse(atob(payload));

    return userInfo.user.id || userInfo.sub;
  } catch (e) {
    return null;
  }
}

// DN stores their user info in local storage
function getUserIdFromLocalStorage() {
  try {
    const userInfo = window.localStorage.getItem("userInfo");

    if (!userInfo) return null;

    return JSON.parse(decodeURIComponent(userInfo)).userId.split(".").pop();
  } catch (e) {
    return null;
  }
}

// Expressen Lifestyle stores their user info in a cookie
function getUserIdFromCookie() {
  const whoami = getCookie("whoami");

  try {
    if (!whoami) return null;

    return JSON.parse(atob(whoami)).id;
  } catch (e) {
    return null;
  }
}

export { getPublisherProvidedId, isLoggedIn };
