import { getSlotConfig } from "./helpers/bamHelper";
import * as dfpPassback from "./dfpPassback";
import { setCookie } from "./helpers/cookieHelper";
import { slotNames } from "../static/slotNames";
import { slotSizes as staticSlotSizes } from "../static/slotSizes";
import { publish as publishMessage } from "./messages";
import { setUpPrebid } from "../prebid.js";
import { getSegments } from "./helpers/segmentsHelper";
import getJSON from "./getJSON";

let slotIds = [];
let slotIndex = [];

function defineSlots(slotElements, bauConfig, preloadedAds, callback) {
  if (slotElements.length === 0) return;

  if (bauConfig.infinityScroll) {
    slotIndex = [];
  }

  if (bauConfig.resetAds) {
    googletag.destroySlots();
    slotIndex = [];
  }

  const slotInfo = bauConfig.slotNameConfig;

  slotElements = reorderSlotElements(slotElements);

  // If we have tcData (TCF 2.0) and we either just showed the user the CMP, or
  // we don't have consent for cookies, then filter out the welcome page
  if (bauConfig.tcData && (bauConfig.tcData.eventStatus === "useractioncomplete" || !bauConfig.tcData.purpose.consents[1])) {
    slotElements = slotElements.filter((slotElement) => slotElement.getAttribute("data-slot-name") !== "rich_media_premium");
  }

  slotElements.forEach((slotElement) => {
    const slotName = slotElement.getAttribute("data-slot-name");
    const slotBamData = slotInfo[slotName];

    if (!slotBamData || (slotElement.id !== "" && !bauConfig.resetAds)) return;

    const prebidConfig = bauConfig.prebidConfig;

    const definedSlot = defineSlot(bauConfig, slotName, slotBamData, slotElement);

    const slot = getSlotConfig(definedSlot.slotElement.dataset.slotNameNumberTargeting, slotBamData.slots);

    if (preloadedAds !== null) {
      preloadedAds[slotElement.id] = definedSlot;
    }

    if (slot.prebidEnabled && prebidConfig && prebidConfig.prebidEnabled) {
      const prebidData = {
        xandrData: {
          categories: bauConfig.targeting?.categories
        },
        inventory: { categories: bauConfig.targeting?.categories }
      };
      if (!bauConfig.disablePersonalizedAds) {
        const segments = getSegments();
        if (segments) {
          prebidData.xandrData.segment = segments;
          prebidData.visitor = { segment: segments };
          prebidData.mkv = segments.map((segment) => `segment:${segment}`).join(",");
        }
      }
      setUpPrebid(definedSlot, prebidConfig, prebidData, (prebidSlot) => {
        dfpPassback.addDefinedSlot(definedSlot);
        callback(prebidSlot);
      });
    } else {

      dfpPassback.addDefinedSlot(definedSlot);
      // eslint-disable-next-line callback-return
      callback([definedSlot]);
    }
  });
}

function defineNonConsensualAds(slotElements, bauConfig, displayConfig) {

  slotElements.forEach((ad, index) => {
    const slotName = ad.getAttribute("data-slot-name");

    if (!staticSlotSizes[slotName]) return;
    const slotNameNumberStartIndex = bauConfig.slotNameNumberStartIndex ? bauConfig.slotNameNumberStartIndex[slotName] : null;
    const slotNameNumberAttributeIndex = parseInt(ad.getAttribute("data-slot-name-number"), 10);
    const slotNameNumber = generateSlotNameNumber(slotName, slotNameNumberStartIndex, slotNameNumberAttributeIndex);

    ad.setAttribute("data-slot-name-number-targeting", slotNameNumber);
    const id = generateSlotId(slotName);

    let slotSizes;
    if (ad.getAttribute("data-slot-sizes") !== null) {
      slotSizes = parseCustomSizes(ad.getAttribute("data-slot-sizes"));
    } else if (displayConfig?.slotSizes && displayConfig.slotSizes[id]) {
      slotSizes = displayConfig.slotSizes[id];
    } else {
      slotSizes = staticSlotSizes[slotName];
    }

    //const [ width, height ] = staticSlotSizes[slotName];
    const slotTargeting = displayConfig?.slotTargeting && displayConfig.slotTargeting[`${slotName}-${slotNameNumber}`];
    const targeting = getTargetingString({
      ...bauConfig.targeting,
      ...slotTargeting,
      slotName,
      slotNameNo: slotNameNumber,
      tcf: "0",
    });
    const correlationId = Math.random().toFixed(10).split(".").pop();
    const el = document.createElement("div");
    const anchor = document.createElement("a");
    const image = document.createElement("img");
    const sz = encodeURIComponent(slotSizes.map(([width, height]) => `${width}x${height}`).join("|"));

    function collapse() {
      ad.style.display = "none";
      image.style.display = "none";
    }

    anchor.target = "_blank";
    anchor.href = `${bauConfig.privacyProxyUrl}/gampad/jump?iu=${bauConfig.path}&sz=${sz}&t=${targeting}&c=${correlationId}&tile=${index}&pre=1&d_imp=1&d_imp_hdr=1`;

    image.style.display = "block";
    image.style.border = "0";

    const eventData = {
      name: slotName,
      number: slotNameNumber,
      id,
      adData: {
        path: bauConfig.path,
        reportKey: getReportKey(slotName, slotNameNumber, bauConfig.device, bauConfig.pageType, bauConfig.targeting.abTest || 0),
      }
    };

    function onErrorHandler() {
      eventData.isEmpty = true;
      eventData.size = null;
      publishMessage("slotRenderEnded", eventData);
      setFrequencyCookie(eventData, bauConfig.frequencyCookie);
      collapse();
    }

    image.onerror = onErrorHandler;

    image.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = image;
      eventData.isEmpty = width === 1 && height === 1;
      eventData.size = eventData.isEmpty ? null : { width, height };

      publishMessage("slotRenderEnded", eventData);
      setFrequencyCookie(eventData, bauConfig.frequencyCookie);

      if (eventData.isEmpty) {
        collapse();
      } else {
        el.style.width = `${width}px`;
        el.style.height = `${height}px`;
        el.style.margin = "0 auto";
        image.width = width;
        image.height = height;

        publishMessage("resize", eventData);
      }
    };

    getJSON(`${bauConfig.privacyProxyUrl}/gampad/ad?iu=${bauConfig.path}&sz=${sz}&t=${targeting}&c=${correlationId}&tile=${index}&pre=1&d_imp=1&d_imp_hdr=1`, (err, res) => {
      if (err || !(res && res?.imageUrl)) {
        onErrorHandler();
        return;
      }

      // We start to load the ad returned from C.L.A.S
      image.src = `${bauConfig.privacyProxyUrl}${res.imageUrl}`;

      // We ping the impression tracking url for the given ad
      (new Image()).src = `${bauConfig.privacyProxyUrl}${res.delayedImpressionTrackingUrl}`;
    });

    ad.appendChild(el).appendChild(anchor).appendChild(image);
    ad.id = id;
    ad.setAttribute("data-slot-sizes", toSizeString(slotSizes));
  });

  function getTargetingString(o) {
    return encodeURIComponent(
      Object.keys(o)
        .filter((k) => !!(k && o[k]))
        .map((k) =>
          `${encodeURIComponent(k)}=${encodeURIComponent(Array.isArray(o[k]) ? o[k].join(",") : o[k])}`
        )
        .join("&")
      );
  }
}

function isPixelSize(slotSizes) {
  return slotSizes.length === 1 && slotSizes[0][0] === 1 && slotSizes[0][1] === 1;
}

function reorderSlotElements(slotElements) {
  const reorderedArray = [];
  const slotsByPriority = ["rich_media_premium", "panorama_top", "panorama"];

  slotsByPriority.forEach((slotName) => {
    slotElements.some((element, index) => {
      if (element.getAttribute("data-slot-name") === slotName) {
        reorderedArray.push(slotElements.splice(index, 1)[0]);
        return true;
      }
    });
  });

  return reorderedArray.concat(slotElements);
}

function toSizeString(sizes) {
  return sizes.map((size) => {
    return size.join("x");
  }).join(",");
}

function parseCustomSizes(sizes) {
  return sizes.split(/\s*,\s*/).map((size) => {
    return size.split("x").map((str) => +str);
  });
}

function generateSlotNameNumber(slotName, startIndex, attributeIndex) {
  if (attributeIndex) {
    slotIndex[slotName] = attributeIndex;
    return slotIndex[slotName];
  }

  if (slotIndex[slotName]) {
    slotIndex[slotName]++;
  } else {
    slotIndex[slotName] = startIndex ? startIndex : 1;
  }
  return slotIndex[slotName];
}

function generateSlotId(slotName) {
  if (slotIds[slotName]) {
    slotIds[slotName]++;
  } else {
    slotIds[slotName] = 1;
  }
  return `${slotName}-${slotIds[slotName]}`;
}

function getReportKey(slotName, slotNameNumber, device, pageType, abTestValue) {
  const deviceAbbrev = device[0];
  const pageTypeAbbrev = pageType.substr(0, 3);
  abTestValue = parseInt(abTestValue, 10);
  abTestValue = abTestValue > 0 && abTestValue <= 100 ? abTestValue : 0;

  return `${deviceAbbrev}/${pageTypeAbbrev}/${slotName}/${slotNameNumber}/${abTestValue}`;
}

function mapSlotNameToPos(slotName, slotNameNo) {
  if (/\d+$/.test(slotName)) return slotName;

  const excludeFromMapping = [
    "bottom",
    "mob_bottom",
    "mob_rich_media_premium",
    "panorama_top",
    "rich_media_panoramainscreen",
    "rich_media_premium",
    "rich_media_takeover",
    "rich_media_wallpaper",
    "special_hockeystick"
  ];

  if (excludeFromMapping.indexOf(slotName) >= 0) return slotName;

  return slotName + slotNameNo;
}

function clearSlots() {
  googletag.cmd.push(() => {
    googletag.destroySlots();
  });

  if (window.pbjs && window.pbjs.adserverRequestSent) window.pbjs.adserverRequestSent = {};

  slotIndex = [];
}

function clearSlotIds() {
  slotIds = [];
}

function setFrequencyCookie(adUnit, frequencyCookie) {
  if (!(frequencyCookie && frequencyCookie.name && frequencyCookie.expire)) return;
  if (adUnit.name !== slotNames.RICH_MEDIA_PREMIUM && adUnit.name !== slotNames.MOB_RICH_MEDIA_PREMIUM) return;
  setCookie(frequencyCookie.name, true, frequencyCookie.expire / 24, undefined, "Lax");
}

function defineSlot(bauConfig, slotName, slotBamData, slotElement) {
  const definedSlot = {};
  const slotTargeting = bauConfig.slotTargeting;
  const abTestValue = bauConfig.targeting.abTest || 0;

  const slotNameNumberStartIndex = bauConfig.slotNameNumberStartIndex ? bauConfig.slotNameNumberStartIndex[slotName] : null;
  const slotNameNumberAttributeIndex = parseInt(slotElement.getAttribute("data-slot-name-number"), 10);
  const slotNameNumber = generateSlotNameNumber(slotName, slotNameNumberStartIndex, slotNameNumberAttributeIndex);

  slotElement.setAttribute("data-slot-name-number-targeting", slotNameNumber);
  const slotId = generateSlotId(slotName);
  const slotPosition = `${slotName}-${slotNameNumber}`;

  const slot = getSlotConfig(slotNameNumber, slotBamData.slots);

  let slotSizes;
  if (slotElement.getAttribute("data-slot-sizes") !== null) {
    slotSizes = parseCustomSizes(slotElement.getAttribute("data-slot-sizes"));
  } else if (bauConfig.slotSizes && bauConfig.slotSizes[slotPosition]) {
    slotSizes = bauConfig.slotSizes[slotPosition];
  } else {
    slotSizes = slot.sizes.slice(0);
  }

  slotElement.setAttribute("data-slot-sizes", toSizeString(slotSizes));

  if (!isPixelSize(slotSizes) && !["panorama_top", "mob_storyline"].includes(slotName) && !(slotName.includes("outsider") && bauConfig.path.includes("/bp/"))) {
    slotSizes.push("fluid");
  }

  definedSlot.slot = googletag.defineSlot(bauConfig.path, slotSizes, slotId)
    .addService(googletag.pubads())
    .setTargeting("pos", mapSlotNameToPos(slotName, slotNameNumber))
    .setTargeting("slotName", slotName)
    .setTargeting("slotNameNo", slotNameNumber)
    .setTargeting("reportKey", getReportKey(slotName, slotNameNumber, bauConfig.device, bauConfig.pageType, abTestValue));

  if (slotTargeting && slotTargeting[slotPosition]) {
    const targeting = slotTargeting[slotPosition];
    Object.keys(targeting).forEach((key) => {
      definedSlot.slot.setTargeting(key, targeting[key]);
    });
  }

  const dataSlotTargeting = slotElement.getAttribute("data-slot-targeting");

  if (dataSlotTargeting) {
    const targetings = dataSlotTargeting.split(",");
    targetings.forEach((targetingString) => {
      const targeting = targetingString.split(":");
      const targetingKey = targeting[0];
      const targetingValue = targeting[1];
      definedSlot.slot.setTargeting(targetingKey, targetingValue);
    });
  }

  definedSlot.slotElement = slotElement;
  slotElement.setAttribute("id", slotId);

  googletag.display(slotId);
  return definedSlot;
}


export {
  defineSlots,
  defineNonConsensualAds,
  getReportKey,
  clearSlots,
  clearSlotIds
};
