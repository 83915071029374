import {setCookie, getCookie} from "./helpers/cookieHelper";

function init() {
  const bauConsoleValue = getBauConsoleValue();

  if (!bauConsoleValue) return;

  if (bauConsoleValue === "local") {
    openConsole("local");
  } else if (bauConsoleValue === "stage") {
    openConsole("stage");
  } else if (bauConsoleValue === "true") {
    openConsole();
  }
}

function getBauConsoleValue() {
  const cookieValue = getCookie("bauConsole");

  const regExp = new RegExp(/bauConsole=([^&]*)/);
  const queryParamValue = window.location.search.match(regExp);

  return queryParamValue && queryParamValue[1] || cookieValue;
}

function openConsole(environment) {
  const bauConsoleScript = window.document.createElement("script");

  if (window.document.getElementById("bauConsoleScript")) return;

  bauConsoleScript.setAttribute("type", "text/javascript");
  bauConsoleScript.id = "bauConsoleScript";
  bauConsoleScript.src = environment === "local"
    ? "https://localhost:8080/dist/bau-console.js" : environment === "stage"
      ? "https://assets.bonad.io/dfp/stage/bau-console.js" : "https://assets.bonad.io/dfp/bau-console.js";
  window.document.body.appendChild(bauConsoleScript);
  setCookie("bauConsole", environment === "local" ? "local" : environment === "stage" ? "stage" : "true");
}

export {init, openConsole};
