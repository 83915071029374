import excludedSites from "../static/excludedSitesFromTintin";

function getAdInfoCss() {
  if (!excludedSites.find((it) => window.location.hostname.replace(/^www\./, "").match(it))) {
    return `.ad-info{
pointer-events:none;
padding-bottom:18px;
position:relative;
}
.ad-info:after{
line-height:1;
content:"⋯";
display:block;
font-family:Helvetica,Arial,sans-serif;
font-size:25px;
cursor:pointer;
color:#22222;
pointer-events:all;
position:absolute;
right:4px;
top:0;
}
.ad-info>div{
pointer-events:all;
display:inline-block;
}`;
    } else {
      return ".ad-info{pointer-events:none;padding-bottom:18px;position:relative;}.ad-info:after{line-height:1;display:block;font-family:Helvetica,Arial,sans-serif;font-size:11px;cursor:pointer;color:dimgrey;pointer-events:all;position:absolute;text-decoration:underline;right:4px;bottom:0;}.ad-info>div{pointer-events:all;display:inline-block;}";
    }
}

export default getAdInfoCss;
