import rollingHash from "@bonniernews/rolling-hash";

async function hashFunction(str) {
  return window.crypto.subtle
    .digest("SHA-256", new TextEncoder().encode(str))
    .then((arrayBuffer) => {
      return Array.from(new Uint8Array(arrayBuffer));
    });
}

function toHexFunction(hash) {
  return hash.map((b) => b.toString(16).padStart(2, "0")).join("");
}

function toBase64Function(hash) {
  const hashedString = hash.map((b) => String.fromCharCode(b)).join("");
  return window.btoa(hashedString);
}

function getRolledHash(input, callback) {
  if (!window.crypto) {
    return callback(new Error("No crypto"));
  }

  rollingHash(input, { hashFunction, toBase64Function, toHexFunction }).then(
    (rolledHash) => {
      return callback(null, rolledHash);
    }
  );
}
export { getRolledHash };
